import React, { useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Form, Button, Card } from "semantic-ui-react";
import classes from "./TotalAgentData.module.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import da from "date-fns/locale/da";

registerLocale("da", da);

const TotalAgentData = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const URL = `https://api-prod01.ipnordic.dk/api/Statistics/CDR/v2/TotalAgentData?startDate=${startDate}&endDate=${endDate}&company=117453`;
  const fetchStats = async () => {
    const res = await axios(URL, {
      auth: {
        username: "mathias.jacobsen@ipnordic.dk",
        password: "QF3az&Z^&`FcZtmU#5ek",
      },
    });
    return res;
  };

  const { isLoading, isError, data, refetch } = useQuery(
    ["stats", startDate, endDate],
    fetchStats,
    {
      enabled: false,
    }
  );

  const formatDate = (date) => {
    const formatedate = format(new Date(date), "yyyy-MM-dd");
    return formatedate;
  };

  const filteredPersons =
    data &&
    data.data.filter(
      (person) => person.Number === "303" || person.Number === "360"
    );

  return (
    <div>
      <Form className={classes.formInline}>
        <Form.Group widths="equal">
          <Form.Field>
            <label style={{ color: "white" }} htmlFor="startdate">
              Start dato
            </label>
            <DatePicker
              locale="da"
              id="startdate"
              dateFormat="yyyy-MM-d"
              value={startDate}
              onChange={(date) => setStartDate(formatDate(date))}
            />
          </Form.Field>

          <Form.Field>
            <label style={{ color: "white" }} htmlFor="enddate">
              Slut dato
            </label>
            <DatePicker
              locale="da"
              id="enddate"
              dateFormat="yyyy-MM-d"
              value={endDate}
              onChange={(date) => setEndDate(formatDate(date))}
            />
          </Form.Field>
        </Form.Group>
        {startDate === "" ? (
          <Button
            className={classes.buttongroup}
            disabled
            size="large"
            onClick={(e) => {
              e.preventDefault();
              refetch();
            }}
          >
            Søg
          </Button>
        ) : endDate === "" ? (
          <Button
            className={classes.buttongroup}
            disabled
            size="large"
            onClick={(e) => {
              e.preventDefault();
              refetch();
            }}
          >
            Søg
          </Button>
        ) : (
          <Button
            size="large"
            className={classes.buttongroup}
            onClick={(e) => {
              e.preventDefault();
              refetch();
            }}
          >
            Søg
          </Button>
        )}
      </Form>

      {isLoading && <div>Henter data...</div>}
      {isError && <div>Kunne ikke hente data!</div>}
      {data &&
        filteredPersons.map((agent) => (
          <React.Fragment key={Math.random()}>
            {agent.Name !== "" ? (
              <Card centered>
                <Card.Content>
                  <Card.Header>{agent.Name}</Card.Header>
                  <Card.Meta>{agent.Location}</Card.Meta>
                  <Card.Description>
                    <div>Total opkald: {agent.Calls}</div>
                    <div>Total besvaret: {agent.AnsweredIn}</div>
                  </Card.Description>
                </Card.Content>
              </Card>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default TotalAgentData;
